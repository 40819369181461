<template>
  <div
    :class="{
      'mdc-text-field--disabled': disabled,
    }"
    class="mdc-text-field mb-2"
  >
    <input
      :id="inputId"
      ref="input"
      :aria-controls="`${inputId}-helper-text`"
      :aria-describedby="`${inputId}-helper-text`"
      :type="type"
      :name="name"
      :value="value"
      :required="required"
      :readonly="readonly"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :min="type === 'number' ? min : undefined"
      :max="type === 'number' ? max : undefined"
      class="mdc-text-field__input bg-white"
      @input="processInput"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @click="$emit('click', $event)"
      @keydown="$emit('keydown', $event)"
    />
    <label
      :for="inputId"
      :class="{ 'mdc-floating-label--float-above': value }"
      class="mdc-floating-label"
      v-html="label"
    />
    <div class="mdc-line-ripple" />
  </div>
</template>

<script>
import { MDCTextField } from '@material/textfield';

export default {
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 999,
    },
  },
  data: () => ({
    mdcTextField: null,
  }),
  computed: {
    inputId() {
      return this.id || this._uid;
    },
  },
  watch: {
    invalid(newVal) {
      this.mdcTextField.valid = !newVal;
    },
  },
  mounted() {
    this.mdcTextField = new MDCTextField(this.$el);
  },
  beforeDestroy() {
    this.mdcTextField.destroy();
  },
  methods: {
    processInput($event) {
      let val = $event.target.value;
      if (this.type === 'number') {
        val = Number.parseInt(val, 10);
        if (val < this.min) val = this.min;
        else if (val > this.max) val = this.max;
        this.$refs.input.value = val;
      }
      this.$emit('input', val);
    },
  },
};
</script>
