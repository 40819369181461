<template>
  <div class="sm:p-8">
    <div class="mx-auto sm:max-w-sm w-full bg-white shadow-md rounded p-4">
      <form v-if="!success" @submit.prevent="submitYo">
        <h1>Forgot Password?</h1>
        <p class="pt-4 font-light">
          We'll email the link to reset your password.
        </p>
        <mdc-textfield
          v-model="email"
          label="Email"
          type="email"
          class="w-full"
          required
          autocomplete="username email"
        />
        <mdc-button
          :disabled="$store.state.loading"
          class="mt-8"
          type="submit"
          primary
          raised
          v-text="'Submit'"
        />
      </form>
      <div v-else>
        <h1>Email Sent</h1>
        <p class="pt-4 font-light">
          Check your inbox. You may close this window.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/xhr';
import MdcButton from '@/components/mdc/button.vue';
import MdcTextfield from '@/components/mdc/textfield.vue';

export default {
  components: {
    MdcButton,
    MdcTextfield,
  },

  data: () => ({
    email: '',
    success: false,
  }),

  mounted() {
    if (this.$route && this.$route.params && this.$route.params.email)
      this.email = this.$route.params.email;
  },

  methods: {
    submitYo() {
      axios.post('/forgot-password', { email: this.email }).then(() => {
        this.success = true;
      });
    },
  },
};
</script>
